import React, { CSSProperties } from 'react';

import { ImageFitMode, generateImageLink } from '../../utils/imagekit';

export interface ImageProps {
  url: string;
  alt: string;
  width?: number;
  ar?: {
    width: number;
    height: number;
  };
  fitMode?: ImageFitMode;
  sizes: string;
  className?: string;
  style?: CSSProperties;
  isLazy?: boolean;
}

export const Image: React.FC<ImageProps> = (props) => {
  let { url, alt, sizes, className, style, width = 400, ar, fitMode = ImageFitMode.Clip, isLazy } = props;

  let srcset = React.useMemo(() => {
    return [0.5, 1, 2]
      .map((multiplier) => {
        let calculatedWidth = width * multiplier;

        return `${generateImageLink(url, {
          width: calculatedWidth,
          ar,
          fitMode,
        })} ${calculatedWidth}w`;
      })
      .join(',');
  }, [url]);

  let fallback = generateImageLink(url, { width, ar });

  return fallback ? (
    <img
      srcSet={srcset}
      sizes={sizes}
      src={fallback}
      alt={alt}
      title={alt}
      className={className}
      style={style}
      loading={isLazy ? 'lazy' : 'eager'}
    />
  ) : null;
};
