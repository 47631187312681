import React from 'react';
import NextLink from 'next/link';
import classNames from 'classnames';

import formatCurrency from '../../utils/format-currency';
import { ProductObjectType } from '../../../generated/graphql';
import { ImageFitMode } from '../../utils/imagekit';
import { Image } from '../elements/image';
import { useLocale } from '../../hooks/useLocale';

interface BannerProps {
  className?: string;
  children: React.ReactNode;
}

export const ProductCardBanner: React.FC<BannerProps> = (props) => {
  let { children, className } = props;

  return (
    <div
      className={classNames(
        className,
        'flex justify-center py-2 px-8 font-medium uppercase bg-cambridge-blue-200 text-white text-lg absolute right-0 top-0 z-40'
      )}
    >
      {children}
    </div>
  );
};

type Props = {
  product: ProductObjectType;
};

export default function ProductCard(props: Props) {
  const { product } = props;
  const locale = useLocale();

  const hasDiscount = !!Math.round(product.discount ?? 0);
  const productPrice = Math.round(hasDiscount ? product.price * (1 - product.discount / 100) : product.price);
  const filteredSizes = product.stock.filter((p) => !!p.amount);

  const isSale = Boolean(hasDiscount || product.isSale || product.isOutlet);
  return (
    <NextLink
      href="/products/[id]"
      as={`/products/${product._id}`}
      className="block w-full"
      aria-label={`Bekijk product ${product.name[locale]}`}
    >
      <div className="w-full relative">
        {isSale ? <ProductCardBanner>Sale</ProductCardBanner> : null}
        <Image
          url={product.pictures[0]}
          width={600}
          fitMode={ImageFitMode.Crop}
          alt={product.name[locale]}
          sizes="(max-width: 350px) 300px, 600px"
          isLazy
          ar={{
            width: 0.75,
            height: 1,
          }}
        />
      </div>
      <div className="pt-1 pb-2">
        <div className="text-lg font-medium text-raw-umber">{product.name[locale]}</div>

        <div className="block text-md text-desert-sand">
          {filteredSizes
            .map((s) => {
              return s.displaySize;
            })
            .sort((a, b) => a.localeCompare(b))
            .join(', ')}
        </div>

        <div className="flex items-center text-md">
          {!!hasDiscount && (
            <span className="line-through mr-2 text-desert-sand">{formatCurrency(product.price / 100, false)}</span>
          )}
          <span className="flex-initial text-raw-umber">{formatCurrency(productPrice / 100, false)}</span>
        </div>
      </div>
    </NextLink>
  );
}
