import querystring from 'query-string';

export enum ImageFitMode {
  Clip = 'clip', // Default value
  Crop = 'crop',
  Fill = 'fill',
}

interface ImageOptions {
  width?: number;
  fitMode?: ImageFitMode;
  ar?: {
    width: number;
    height: number;
  };
}

export function generateImageLink(url: string, opts: ImageOptions = {}) {
  if (!url) {
    return url;
  }

  let { width = 200, fitMode = ImageFitMode.Clip, ar } = opts;
  let transformations = [];
  transformations.push(`w-${width}`);

  switch (fitMode) {
    case ImageFitMode.Clip:
      transformations.push('c-at_max');
      break;
    case ImageFitMode.Crop:
      transformations.push('c-maintain_ratio');
      break;
    case ImageFitMode.Fill:
      transformations.push('cm-pad_resize');
      transformations.push('bg-FFFFFF');
      break;
    default:
      break;
  }

  if (ar) {
    transformations.push(`ar-${Math.round(ar.width * 100)}-${Math.round(ar.height * 100)}`);
  }

  let parsedUrl = querystring.parseUrl(url);
  return querystring.stringifyUrl({
    url: parsedUrl.url,
    query: {
      tr: transformations.join(','),
    },
  });
}
