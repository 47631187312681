import React from 'react';

import ProductCard from './product-card';
import { ProductObjectType } from '../../../generated/graphql';

type Props = {
  products: Array<ProductObjectType>;
};

export default function Products(props: Props) {
  let { products } = props;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
      {Array.isArray(products) && products.length > 0 ? (
        products.map((p) => {
          return <ProductCard product={p} key={p._id} />;
        })
      ) : (
        <span>Geen artikels gevonden</span>
      )}
    </div>
  );
}
